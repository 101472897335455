<template>
  <div class="comments-section">
    <h2>Comentarios</h2>
    <div v-if="comments.length > 0">
      <div v-for="comment in comments" :key="comment.id" class="comment">
        <p>Pregunta: {{ comment.text }}</p>
        <p class="response" v-if="comment.response">Respuesta: {{ comment.response }}</p>
        <div v-if="!comment.response">
          <input class="input" v-model="commentResponse[comment.id]" placeholder="Escribe una respuesta" />
          <br>
          <button @click="responseComment(comment.id, comment.user_id, comment.product_id)">Enviar</button>
        </div>
      </div>
    </div>
    <p v-else>No hay comentarios para este producto.</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CommentsSection',
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      commentResponse: {} // Almacena las respuestas para cada comentario
    }
  },
  methods: {
    ...mapActions(['responseCommentProduct']),
    async responseComment (commentId, userId, productId) {
      const response = this.commentResponse[commentId]
      console.log('Sending response:', response)
      this.$router.go()

      if (response && response.trim()) {
        const success = await this.responseCommentProduct({
          idComment: commentId,
          userId: userId,
          productId: productId,
          response: response
        })
        console.log('Response sent:', success)

        if (success) {
          this.commentResponse[commentId] = ''
          this.$router.replace({ name: 'your-route-name' }).then(() => {
            this.$router.go()
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.comments-section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.comment {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.comment:last-child {
  border-bottom: none;
}

.comment p {
  margin: 10px 0;
}

.response {
  color: #666;
  font-style: italic;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #003d7a;
}

button:disabled {
  background-color: #ccc;
  cursor: default;
}
</style>
